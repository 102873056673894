import React, { useContext, useEffect } from 'react';
import { usePagination } from '../../context/PaginationContext';
import { AuthenticatedUserContext } from '../../context/AuthenticatedUserContext';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { getData } from '../../graphql/queries/getData';
import RawDataTable from './RawDataTable';
import { DateSelection } from '../../types/DateSelection';
import { classNames } from '../../utils/utils';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Card } from '../../components/Layout/Card/Card';

export default function Data() {
  const { user } = useContext(AuthenticatedUserContext);
  const { limit, offset, setOffset } = usePagination();
  const { useQuery } = useOrganisationAwareApollo();
  const [dateSelection, setDateSelection] = React.useState('ALL_TIME');
  const [importId, setImportId] = React.useState<string | null>(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
    window.scrollTo(0, 0);
  };
  const fetchOptions = {
    variables: {
      input: {
        limit,
        offset: offset,
      },
      filter: {
        where: [],
      },
      dateSelection: dateSelection,
    },
  };

  if (importId) {
    fetchOptions.variables.filter.where = [
      // @ts-ignore
      {
        field: 'importId',
        value: importId,
      },
    ];
  }

  const { data, refetch } = useQuery(getData, fetchOptions);

  const tableData = data?.getData?.results ?? [];
  const count = data?.getData?.count ?? 0;

  useEffect(() => {
    if (user.isAuthenticated) {
      setImportId(searchParams.get('importId'));
      refetch();
    }
  }, [user.isAuthenticated, limit, offset, importId]);

  return (
    <Card>
      <Card.Header>
        <div className="bg-white px-2 sm:px-6">
          <div className="flex sm:items-end items-center flex-wrap justify-between">
            <div className="flex flex-wrap">
              <h3 className="font-semibold">Data</h3>
            </div>
            <div className="flex flex-wrap">
              <div className="mt-5 sm:flex sm:items-center">
                {importId && (
                  <div
                    role="button"
                    className={classNames(
                      'mt-3 inline-flex w-full items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  sm:ml-3 sm:mt-0 sm:w-auto',
                      'bg-primary hover:bg-green-600 focus-visible:outline-green-600'
                    )}
                    onClick={async () => {
                      navigate(`/data`);
                      await setImportId(null);
                    }}
                  >
                    View All Data
                  </div>
                )}
                <Link
                  type="submit"
                  className={classNames(
                    'mt-3 inline-flex w-full items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  sm:ml-3 sm:mt-0 sm:w-auto',
                    'bg-primary hover:bg-green-600 focus-visible:outline-green-600'
                  )}
                  to={'/data/import'}
                >
                  Imports
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <p className="text-sm text-white">Date Selection:</p>
        <select
          value={dateSelection}
          className="text-sm"
          onChange={(event) =>
            setDateSelection(event.target.value as DateSelection)
          }
        >
          {Object.entries(DateSelection).map((option) => (
            <option key={option[0]} value={option[0]}>
              {option[1]}
            </option>
          ))}
        </select>
        <RawDataTable
          productPurchases={tableData}
          count={count}
          setPage={handlePageChange}
          pageSize={limit}
          navigate={navigate}
          setImportId={setImportId}
          pageNumber={Math.floor(offset / limit) + 1}
        />
      </Card.Body>
    </Card>
  );
}
