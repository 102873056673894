import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from 'aws-amplify';
import config from '../aws-exports';
import { useContext, useEffect, useState } from 'react';
import { AuthenticatedUserContext } from '../context/AuthenticatedUserContext';

export const graphqlHeaders = async () => {
  // pass JWT if it exists
  let jwtToken;
  try {
    jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  } catch (err) {
    // not authenticated
  }
  return jwtToken;
};

export const useApolloClient = () => {
  const defaultClient = new ApolloClient({
    cache: new InMemoryCache(),
  });

  const [apolloClient, setApolloClient] = useState(defaultClient);

  const configuration = config as Record<string, any>;

  const apiBaseUrl = configuration?.API?.graphql_endpoint;

  async function createApolloClient() {
    const token = await graphqlHeaders();

    if (apiBaseUrl) {
      const httpLink = new HttpLink({ uri: apiBaseUrl });

      // @ts-ignore
      const authLink = setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        };
      });

      const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache({
          addTypename: false,
        }),
      });
      setApolloClient(client);
    }
  }

  const { user } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    createApolloClient();
  }, [user.isAuthenticated]);

  return apolloClient;
};
