import { gql } from '@apollo/client';

export const deleteDataFromImport = gql`
  mutation DeleteDataFromImport(
    $activeOrganisation: String
    $input: DeleteDataFromImportInput
  ) {
    deleteDataFromImport(
      activeOrganisation: $activeOrganisation
      input: $input
    ) {
      message
      success
    }
  }
`;
