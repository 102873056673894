import { ErrorMessage, useFormikContext } from 'formik';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';

interface FileInputProps {
  id: string;
  label?: string;
  filetypes?: string;
  filetypesDescription?: string;
  value?: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  clearForm?: any;
  removalAllowed?: boolean;
}

export const FileInput = (props: FileInputProps) => {
  const {
    id,
    label = '',
    filetypes = 'image/png, image/jpeg, image/gif',
    filetypesDescription = 'PNG, JPG, GIF up to 10MB',
    value,
    removalAllowed = false,
    setFieldValue,
    clearForm,
  } = props;

  const { resetForm } = useFormikContext();

  return (
    <>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <ErrorMessage name={id} component="div" className="text-red-700" />
      <div className="mt-2 flex justify-start w-full">
        {value?.name ? (
          <div className="relative flex flex-col place-content-center">
            <div className="flex justify-center">
              <p>{value.name}</p>
            </div>
            {removalAllowed && (
              <span
                onClick={() => {
                  setFieldValue(id, {});
                  resetForm();
                  clearForm();
                }}
                className="relative cursor-pointer rounded-md pt-4 font-semibold text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-600 focus-within:ring-offset-2 hover:text-primary-500"
              >
                Remove this file
              </span>
            )}
          </div>
        ) : (
          <div className="text-center flex flex-col place-content-center">
            <div className="mt-4 w-full flex text-sm leading-6 text-gray-600">
              <label
                htmlFor={id}
                className="relative cursor-pointer w-full px-32 md:px-52 lg:px-96 py-16 border rounded-md font-semibold text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-600 focus-within:ring-offset-2 hover:text-primary-500"
              >
                <DocumentPlusIcon
                  className="mx-auto mb-2 h-12 w-12 text-gray-300"
                  aria-hidden="true"
                />
                <span>Upload a file</span>
                <input
                  accept={filetypes}
                  id={id}
                  name={id}
                  type="file"
                  className="sr-only w-full"
                  onChange={(event: any) => {
                    if (event.currentTarget.files) {
                      setFieldValue(id, event.currentTarget.files[0]);
                    }
                  }}
                />
                <p className="text-xs leading-5 text-gray-600">
                  {filetypesDescription}
                </p>
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
