import { ClockIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useState } from 'react';

export function DigitalClock() {
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const hours = dateTime.getHours().toString().padStart(2, '0');
  const minutes = dateTime.getMinutes().toString().padStart(2, '0');
  const seconds = dateTime.getSeconds().toString().padStart(2, '0');

  return (
    <div className="digital-clock flex flex-col items-center w-48 justify-center py-2 rounded-lg">
      <div className="date">{moment(dateTime).format('LL')}</div>
      <div className="time text-2xl flex items-center gap-x-2">
        <p>
          {hours}:{minutes}:{seconds}
        </p>
      </div>
    </div>
  );
}
