import { AuthenticatedUserProvider } from './AuthenticatedUserContext';
import { NotificationProvider } from './NotificationContext';
import React from 'react';

interface ContextWrapperProps {
  children: React.ReactElement;
}

export const ContextWrapper = ({ children }: ContextWrapperProps) => {
  return (
    // @ts-ignore
    <AuthenticatedUserProvider>
      <NotificationProvider>{children}</NotificationProvider>
    </AuthenticatedUserProvider>
  );
};
