import {
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
} from '@heroicons/react/20/solid';
import { useState } from 'react';

export const CustomLegend = ({
  chartData,
  onLegendItemClick,
  isExpanded,
  datasetVisibility,
}: any) => {
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);

  const totalItems =
    chartData?.labels && chartData?.datasets?.length === 1
      ? chartData?.labels?.length
      : chartData?.datasets?.length;

  const numberOfPages = Math.ceil(totalItems / itemsPerPage);

  const handleLegendItemClick = (index: any) => {
    onLegendItemClick(index);
  };

  const getLegendItemColor = (datasetIndex: number, labelIndex: number) => {
    const dataset = chartData?.datasets?.[datasetIndex];

    const colorIndex = labelIndex % (dataset?.backgroundColor.length || 1);
    const color = Array.isArray(dataset?.backgroundColor)
      ? dataset?.backgroundColor[colorIndex]
      : dataset?.backgroundColor;
    return color || '#000';
  };

  const renderLegendItems = () => {
    if (chartData?.labels && chartData?.datasets?.length === 1) {
      const startIndex = currentPage * itemsPerPage;
      const endIndex = Math.min(
        startIndex + itemsPerPage,
        chartData?.labels?.length
      );
      return chartData?.labels
        ?.slice(startIndex, endIndex)
        .map((label: string, index: number) => {
          const colorIndex = startIndex + index;
          const color = getLegendItemColor(0, colorIndex);
          return (
            <div
              key={index}
              onClick={() => handleLegendItemClick(label)}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <span
                style={{
                  display: 'inline-block',
                  width: '14px',
                  height: '14px',
                  backgroundColor: color,
                  marginRight: '8px',
                  borderRadius: '50%',
                }}
              ></span>
              <span>
                {datasetVisibility?.[label] === false ? <s>{label}</s> : label}
              </span>
            </div>
          );
        });
    } else {
      const startIndex = currentPage * itemsPerPage;
      const endIndex = Math.min(
        startIndex + itemsPerPage,
        chartData?.datasets?.length
      );
      return chartData?.datasets
        ?.slice(startIndex, endIndex)
        .map((dataset: any, index: number) => {
          const color = dataset?.backgroundColor;
          return (
            <div
              key={index}
              onClick={() => handleLegendItemClick(dataset?.label)}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <span
                style={{
                  width: '14px',
                  height: '14px',
                  backgroundColor: color,
                  marginRight: '8px',
                  borderRadius: '50%',
                  display: 'inline-block',
                }}
              ></span>
              <span>
                {datasetVisibility?.[dataset?.label] === false ? (
                  <s>{dataset?.label}</s>
                ) : (
                  dataset?.label
                )}
              </span>
            </div>
          );
        });
    }
  };

  return (
    <div>
      <div
        className={`flex justify-between  pt-1 w-full gap-x-4 ${
          isExpanded ? 'pb-24' : 'h-[60px]'
        }`}
      >
        {numberOfPages > 1 ? (
          <button
            disabled={currentPage <= 0}
            className="disabled:opacity-50"
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <ArrowSmallLeftIcon
              className="h-5 w-5 text-black"
              aria-hidden="true"
            />
          </button>
        ) : (
          <div />
        )}
        <div
          className={`grid py-2 w-full items-center gap-4 gap-y-2 px-2 ${
            isExpanded ? 'text-sm grid-cols-3' : 'text-xs grid-cols-2'
          }`}
        >
          {renderLegendItems()}
        </div>
        {numberOfPages > 1 ? (
          <button
            disabled={currentPage >= numberOfPages - 1}
            className="disabled:opacity-50"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <ArrowSmallRightIcon
              className="h-5 w-5 text-black"
              aria-hidden="true"
            />
          </button>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
