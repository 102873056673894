import { useParams } from 'react-router-dom';
import { Card } from '../../../../components/Layout/Card/Card';
import { deleteImport } from '../../../../graphql/queries/deleteImport';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { classNames } from '../../../../utils/utils';
import { ImportForm } from './ImportForm';

function downloadCSV() {
  let csvData = [
    'Description',
    'Quantity',
    'Unit Price',
    'Date of Purchase',
    'Supplier',
    'Supplier Location',
    'Manufacturer',
    'Country of Manufacture',
  ].join(',');

  // Create a CSV file and allow the user to download it
  const blob = new Blob([csvData], { type: 'text/csv' });
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.href = url;
  a.download = 'template.csv';
  document.body.appendChild(a);
  a.click();
}

export const ImportNew = ({
  onImportComplete,
  showProgressButton = true,
}: any) => {
  // get the id from path
  const { id } = useParams();

  const { useMutation } = useOrganisationAwareApollo();
  const [deleteImportMutation] = useMutation(deleteImport, {});

  const handleDeleteImport = async (id: string) => {
    await deleteImportMutation({
      variables: {
        input: {
          id,
        },
      },
    });
  };

  return (
    <Card>
      <Card.Header>
        <div className="bg-white px-4 sm:px-6">
          <div className="flex sm:items-end items-center flex-wrap justify-between">
            <h3 className="font-semibold">
              Upload {id ? 'Calculations' : 'Data'}
            </h3>

            <div className="sm:flex sm:items-center">
              <div
                role="button"
                className={classNames(
                  'mt-3 inline-flex w-full items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  sm:ml-3 sm:mt-0 sm:w-auto',
                  'bg-primary hover:bg-green-600 focus-visible:outline-green-600'
                )}
                onClick={() => downloadCSV()}
              >
                Download Template
              </div>
            </div>
          </div>
        </div>
      </Card.Header>

      <Card.Body>
        {/*  Add the form */}
        <ImportForm
          id={id}
          deleteImport={handleDeleteImport}
          onImportComplete={onImportComplete}
          showProgressButton={showProgressButton}
        />
      </Card.Body>
    </Card>
  );
};
