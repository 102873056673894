import React, { useContext, useEffect } from 'react';
import { AuthenticatedUserContext } from '../../context/AuthenticatedUserContext';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { usePagination } from '../../context/PaginationContext';
import SuppliersTable from './SuppliersTable';
import { getSuppliers } from '../../graphql/queries/getSuppliers';
import { OrganisationContext } from '../../context/OrganisationContext';

export default function Suppliers() {
  const { user } = useContext(AuthenticatedUserContext);
  const { useQuery } = useOrganisationAwareApollo();
  const { activeOrganisation } = useContext(OrganisationContext);

  const { limit, offset, setOffset } = usePagination();

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
    window.scrollTo(0, 0);
  };

  const { data, refetch } = useQuery(getSuppliers, {
    variables: {
      input: {
        limit,
        offset,
      },
    },
  });

  const suppliers = data?.getSuppliers?.results ?? [];
  const count = data?.getSuppliers?.count ?? 0;

  useEffect(() => {
    if (user.isAuthenticated) {
      refetch();
    }
  }, [user.isAuthenticated, limit, offset, activeOrganisation]);

  return (
    <div className="flex flex-col mt-2 h-full">
      <div className="mx-8 grow">
        <div className="divide-y divide-gray-200 rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:px-6">
            <div className="bg-white px-4 sm:px-6">
              <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between">
                <div className="ml-4 mt-2">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Suppliers
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:p-6 overflow-y-visible overflow-visible">
            <SuppliersTable
              suppliers={suppliers}
              count={count}
              setPage={handlePageChange}
              pageSize={limit}
              pageNumber={Math.floor(offset / limit) + 1}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
