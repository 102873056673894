import { Link, useLocation } from 'react-router-dom';

interface QuickLinksProps {
  links: any[];
  toggleMenu?: () => void;
}

const isMatch = (path: string, link: string) => {
  return (
    (path === '/' && link === '/') || (path.startsWith(link) && link !== '/')
  );
};

export function QuickLinks({
  links = [],
  toggleMenu = () => {},
}: QuickLinksProps) {
  const location = useLocation();
  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-1 md:grid-cols-4 lg:flex-row lg:flex-wrap gap-y-2 gap-x-6 text-center items-start px-10 lg:items-center">
        {links
          .filter((r) => {
            return r.show;
          })
          .map((link) => (
            <Link to={link.path} onClick={toggleMenu} key={link.label}>
              {/* @ts-ignore */}
              <div
                role="button"
                key={link.label}
                className={`${
                  isMatch(location.pathname, link.path)
                    ? 'bg-primary border-white shadow-lg border-2 active:border-0'
                    : ''
                } px-6 lg:px-3 rounded-lg bg-accent py-2 transition-all hover:bg-primary`}
              >
                <div className="w-full flex justify-center items-center gap-x-2 py-2">
                  {link.icon ? (
                    <div>
                      {link.icon(
                        isMatch(location.pathname, link.path)
                          ? 'white'
                          : 'white'
                      )}
                    </div>
                  ) : null}
                  <p
                    className={`text-lg font-extrabold lg:text-sm ${
                      isMatch(location.pathname, link.path)
                        ? 'text-white'
                        : 'text-white'
                    }`}
                  >
                    {link.label}
                  </p>
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}
