import { useLazyQuery, useQuery } from '@apollo/client';
import { createContext, useContext, useEffect, useState } from 'react';
import { getMyOrganisations } from '../graphql/queries/getMyOrganisations';
import { Organisation } from '../types/Organisation';
import { AuthenticatedUserContext } from './AuthenticatedUserContext';

interface OrganisationProviderProps {
  organisations: Organisation[] | undefined;
  activeOrganisation: Organisation | undefined;
  setActiveOrganisation:
    | React.Dispatch<React.SetStateAction<Organisation | undefined>>
    | undefined;
}

export const OrganisationContext = createContext<OrganisationProviderProps>({
  organisations: undefined,
  activeOrganisation: undefined,
  setActiveOrganisation: undefined,
});

export const OrganisationProvider = ({ children }: any) => {
  const [activeOrganisation, setActiveOrganisation] = useState<
    Organisation | undefined
  >(JSON.parse(sessionStorage.getItem('activeOrganisation') || 'null'));

  const { user } = useContext(AuthenticatedUserContext);

  const [fetchMyOrganisations, { data }] = useLazyQuery(getMyOrganisations);
  const organisations = data?.getMyOrganisations?.results;

  useEffect(() => {
    if (user.isAuthenticated) {
      fetchMyOrganisations();
    }
  }, [user.isAuthenticated]);

  useEffect(() => {
    if (organisations && organisations.length > 0) {
      const persistedActiveOrganisation = JSON.parse(
        sessionStorage.getItem('activeOrganisation') || 'null'
      );
      if (
        organisations.find(
          (org: Organisation) => org?.id === persistedActiveOrganisation?.id
        )
      ) {
        setActiveOrganisation(persistedActiveOrganisation);
      } else {
        setActiveOrganisation(organisations[0]);
      }
    }
  }, [organisations, user]);

  useEffect(() => {
    if (activeOrganisation) {
      sessionStorage.setItem(
        'activeOrganisation',
        JSON.stringify(activeOrganisation)
      );
    }
  }, [activeOrganisation]);

  if (organisations) {
    return (
      <OrganisationContext.Provider
        value={{ organisations, activeOrganisation, setActiveOrganisation }}
      >
        {children}
      </OrganisationContext.Provider>
    );
  }

  return null;
};
