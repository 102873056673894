import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { TextInput } from '../../../../components/Forms/Inputs/TextInput';
import { FileInput } from '../../../../components/Forms/Inputs/FileInput';
import Progress from '../../../../components/Forms/Progress';
import colors from 'tailwindcss/colors';
import { XCircleIcon } from '@heroicons/react/20/solid';
import {
  printErrors,
  readFileData,
  saveFileToS3,
  uploadDataToAPI,
} from './utils/utils';
import { createImport } from '../../../../graphql/mutations/createImport';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { saveDataToImport } from '../../../../graphql/mutations/saveDataToImport';
import { getImports } from '../../../../graphql/queries/getImports';
import { Import } from '../../../../types/Import';
import { useNavigate } from 'react-router-dom';

export interface RowFormat {
  // Product Purchase
  quantity: string;
  unitPrice: string;
  dateOfPurchase: string;
  supplierDistance?: string;
  modeOfTransport?: string;
  intensityRatio?: string;
  intensityRatioUnit?: string;
  emissionProduct?: string;
  emissionTransport?: string;
  emissionTotal?: string;
  emissionsUnit?: string;

  // Product
  description: string;
  recycled?: string;
  unitOfMeasure?: string;
  countryOfManufacture?: string;
  sector?: string;
  subSector?: string;

  // Supplier
  supplier: string;
  supplierLocation: string;
  supplierCountry?: string;

  // Manufacturer
  manufacturer?: string;
}

interface ImportFormProps {
  id?: string;
  deleteImport: (id: string) => void;
  onImportComplete: () => Promise<void>;
  showProgressButton?: boolean;
}

export const ImportForm = ({
  id,
  deleteImport,
  onImportComplete = () => Promise.resolve(),
  showProgressButton = true,
}: ImportFormProps) => {
  const { useMutation, useQuery } = useOrganisationAwareApollo();
  const [existingImport, setExistingImport] = useState<Import>();

  let refetch: any = () => {};
  if (id) {
    const { refetch: fetch } = useQuery(getImports, {
      variables: {
        input: {
          id: id,
        },
      },
      onCompleted: (data) => {
        if (!data.getImports?.response?.success) {
          setError('Unable to fetch import, please try again.');
        }
        setExistingImport(data.getImports.results[0]);
        onImportComplete();
      },
    });
    refetch = fetch;
  }

  useEffect(() => {
    refetch();
  }, [id]);

  const initialValues = {
    file: {},
    importName: '',
  } as {
    file: {
      name: string;
    };
    importName: string;
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const [fileChecked, setFileChecked] = useState(false);
  const [fileSaved, setFileSaved] = useState(false);
  const [completedDataUpload, setCompletedDataUpload] = useState(false);
  const [reverted, setReverted] = useState(false);
  const [processingComplete, setProcessingComplete] = useState(false);

  const [error, setError] = useState('');
  const [errors, setErrors] = useState<string[] | undefined>(undefined);
  const [newImport, setNewImport] = useState<Import | null>(null);
  const navigate = useNavigate();

  const [progressBarStates, setProgressBarStates] = useState<
    { title: string; completed: boolean }[]
  >([{ title: 'Checking File', completed: false }]);

  const [saveImport] = useMutation(createImport, {
    onCompleted: async (data) => {
      if (!data.createImport.success) {
        setError('Unable to create an import, please try again.');
        await onImportComplete();
      }
      setExistingImport(data?.createImport?.id);
    },
  });

  const [saveDataToAPI] = useMutation(saveDataToImport, {
    onCompleted: async (data) => {
      if (!data?.saveDataToImport?.success) {
        setError('Unable to create an import, please try again.');
        await onImportComplete();
      }
    },
  });

  const onSubmitForm = async (values: any) => {
    let processingError;
    let resultRows: RowFormat[] = [];
    setIsProcessing(true);

    try {
      const { results, error, errors } = await readFileData(values.file);
      setErrors(errors);
      setError(error);
      if (error != '') {
        processingError = true;
      }
      if (error === '' && results.length == 0) {
        setError('No rows were found in the file, please try again.');
        processingError = true;
      }
      resultRows = results;
      setFileChecked(true);
    } catch (e) {
      console.log(e);
    } finally {
      await onImportComplete();
    }

    if (processingError) {
      setReverted(true);
      setProcessingComplete(true);
      setIsProcessing(false);
      return;
    }

    const hasEmissionsData = resultRows.some(
      (row: RowFormat) => row.emissionTotal
    );
    const result = await saveImport({
      variables: {
        input: {
          name: values.importName,
          id: id ? id : null,
          hasEmissionsData: hasEmissionsData,
          fileName: values.file.name,
        },
      },
    });

    if (!result.data.createImport.success) {
      setError('Unable to create an import, please try again.');
      setReverted(true);
      setProcessingComplete(true);
      setIsProcessing(false);
      return;
    }

    const newId = result.data.createImport.id;
    const presignedUrl = result.data.createImport.presignedUrl;

    setNewImport(result.data.createImport);

    const fileSaved = await saveFileToS3(values.file, presignedUrl);

    if (!fileSaved) {
      setError(
        'There was an error saving your file, please try the import process again.'
      );
      await deleteImport(id || newId);
      setIsProcessing(false);
      setProcessingComplete(true);
      setReverted(true);
      return;
    }

    setFileSaved(fileSaved);

    try {
      const { error, errors } = await uploadDataToAPI(
        resultRows,
        id || newId,
        saveDataToAPI
      );
    } catch (e) {
      console.log(e);
      setError('There was an error uploading your data, please try again.');
      deleteImport(id || newId);
    } finally {
      await onImportComplete();
    }

    if (error && error != '') {
      setError(error);
      if (errors != null) {
        setErrors(errors);
      }
      deleteImport(id || newId);
      setReverted(true);
      setIsProcessing(false);
      return;
    }
    await onImportComplete();
    setCompletedDataUpload(true);
    setProcessingComplete(true);
  };

  useEffect(() => {
    if (error != '') {
      setProgressBarStates([
        { title: 'Checking File', completed: fileChecked },
        { title: 'Cleaning up', completed: reverted },
      ]);
    } else {
      setProgressBarStates([
        { title: 'Checking File', completed: fileChecked },
        { title: 'Saving File', completed: fileSaved },
        { title: 'Importing Data', completed: completedDataUpload },
        { title: 'Finalising', completed: processingComplete },
      ]);
    }
  }, [
    error,
    reverted,
    errors,
    processingComplete,
    isProcessing,
    completedDataUpload,
    fileSaved,
    fileChecked,
  ]);

  return (
    <div>
      <Formik
        // @ts-ignore
        initialValues={initialValues}
        onSubmit={onSubmitForm}
      >
        {({ setFieldValue, values, isValid, handleSubmit, isSubmitting }) => (
          <Form>
            <div className="space-y-4">
              <div className="sm:col-span-4">
                {existingImport ? (
                  <p>
                    Importing data into existing import:{' '}
                    {existingImport.name || existingImport.fileName}
                  </p>
                ) : (
                  <TextInput
                    id="importName"
                    label="Import Name"
                    placeholder="December 2023"
                    value={values.importName}
                    setFieldValue={setFieldValue}
                  />
                )}
              </div>
              <div className="sm:col-span-4">
                <FileInput
                  id={'file'}
                  label={'Import File as CSV'}
                  filetypes={'text/csv'}
                  filetypesDescription={'CSV'}
                  value={values.file}
                  clearForm={() => {
                    setErrors({} as any);
                    setProcessingComplete(false);
                    setError('');
                  }}
                  removalAllowed={!isProcessing}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className="flex flex-col">
                {values.file?.name && !isProcessing && !processingComplete && (
                  <div className="my-2">
                    <button
                      type="button"
                      disabled={isSubmitting || !isValid}
                      onClick={async () => {
                        // @ts-ignore
                        await handleSubmit(values);
                      }}
                      className={`col-span-4 capitalize transition-all justify-center py-2 px-4 border disabled:bg-gray-400 font-semibold border-transparent shadow-sm text-sm rounded-md text-white bg-primary hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                    >
                      Process
                    </button>
                  </div>
                )}
                {isProcessing && (
                  <Progress
                    colour={error != '' ? 'red-400' : 'green-500'}
                    steps={progressBarStates}
                    title={'Importing data'}
                    spinner={{
                      colour: error != '' ? colors.red[500] : colors.green[500],
                      show: !processingComplete,
                    }}
                  />
                )}
              </div>
              <div className="flex flex-col">
                {processingComplete && (
                  <div className="my-2">
                    <p className="text-sm text-gray-500 py-10">
                      {error != '' ? 'Import failed' : `Import complete`}
                    </p>
                    {error == '' && (
                      <button
                        type="button"
                        onClick={async () => {
                          navigate('/data?importId=' + existingImport);
                        }}
                        className={`col-span-4 capitalize transition-all justify-center py-2 px-4 border disabled:bg-gray-400 font-semibold border-transparent shadow-sm text-sm rounded-md text-white bg-primary hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                      >
                        View Imported Data
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            {error != '' && (
              <div className="rounded-md  mt-8 bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-700">
                      {error}
                    </h3>
                    <div className="mt-2 text-sm text-red-700">
                      {printErrors(errors)}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {showProgressButton ? (
              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                  type="button"
                  className={`ml-3 capitalize transition-all justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-gray-100 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300`}
                  onClick={() => (window.location.href = `/data/import`)}
                  disabled={isSubmitting}
                >
                  {processingComplete ? 'Done' : 'Cancel'}
                </button>
              </div>
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
};
