import React, { useContext } from 'react';
import awsConfig from './aws-exports';
import { isLocalhost } from './utils/utils';
import { Amplify } from 'aws-amplify';
import { AuthenticatedUserContext } from './context/AuthenticatedUserContext';
import Login from './components/Login/Login';
import { ContextWrapper } from './context/ContextWrapper';
import RootLayout from './app/layout';
import { ApolloProvider } from '@apollo/client';
import { useApolloClient } from './hooks/useApolloClient';
import NotificationWrapper from './components/Notification/NotificationWrapper';

const [localRedirectSignIn, productionRedirectSignIn] =
  awsConfig.oauth.redirectSignIn.split(',');

const [localRedirectSignOut, productionRedirectSignOut] =
  awsConfig.oauth.redirectSignOut.split(',');

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
};

Amplify.configure(updatedAwsConfig);

function App() {
  const { user } = useContext(AuthenticatedUserContext);
  const apolloClient = useApolloClient();
  if (!user.isAuthenticated) {
    return <Login />;
  }
  return (
    <ApolloProvider client={apolloClient}>
      <div className="App h-screen">
        <RootLayout />
        <NotificationWrapper />
      </div>
    </ApolloProvider>
  );
}

const AppWrapper = () => {
  return (
    <ContextWrapper>
      <App />
    </ContextWrapper>
  );
};
export default AppWrapper;
