import { gql } from '@apollo/client';

export const getEmissionsData = gql`
  query getEmissionsData(
    $query: CalculationQuery
    $activeOrganisation: String
  ) {
    getEmissionsData(query: $query, activeOrganisation: $activeOrganisation) {
      EmissionsByManufacturer {
        manufacturer
        totalEmissions
        transportEmissions
        productEmissions
      }
      EmissionsBySector {
        name
        totalEmissions
        transportEmissions
        productEmissions
      }
      EmissionsBySubSector {
        sectorName
        subSectorName
        totalEmissions
        transportEmissions
        productEmissions
      }
      EmissionsBySupplier {
        name
        totalEmissions
        transportEmissions
        productEmissions
      }
      EmissionsTransportBySupplier {
        modeOfTransport
        name
        totalEmissions
        transportEmissions
        productEmissions
      }
      TotalExpenditurePerKgCO2e {
        totalEmissions
        transportEmissions
        productEmissions
        totalExpenditure
        purchaseMonth
      }
      NoOfSuppliers {
        count
        results {
          name
        }
      }
      TotalExpenditure {
        result
      }
      TotalEmissions {
        result
      }
    }
  }
`;
