import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import { DefaultNav } from '../components/Nav/DefaultNav';

import { OrganisationProvider } from '../context/OrganisationContext';
import './globals.css';
import { QuickLinks } from '../components/Nav/QuickLinks';
import { mainRoutes } from './routes';
import { DigitalClock } from '../components/DigitalClock/DigitalClock';

export default function RootLayout({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <div className={`w-full flex flex-col bg-gray-100 h-full`}>
      <OrganisationProvider>
        <>
          <Header>
            <DefaultNav />
          </Header>
          <div className="my-16 sm:my-16 lg:my-11" />
          <div
            className={`hidden lg:flex items-center justify-evenly px-4 gap-x-5 py-2`}
          >
            <QuickLinks links={mainRoutes} />
            <div className="border-l border-dashed px-2 hidden lg:block">
              <DigitalClock />
            </div>
          </div>
          {children}
          <Outlet />
        </>
      </OrganisationProvider>
    </div>
  );
}
