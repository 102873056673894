import { gql } from '@apollo/client';

export const getImportFiles = gql`
  query GetImportFiles(
    $activeOrganisation: String
    $input: GenericFindOptions
  ) {
    getImportFiles(activeOrganisation: $activeOrganisation, input: $input) {
      message
      success
      rawDataURL
      emissionsDataURL
    }
  }
`;
