import React, { createContext, useState } from 'react';
import { Notification } from '../types/Notification';

interface NotificationProviderProps {
  notifications: Notification[] | undefined;
  addNotification: (notification: Notification) => void | undefined;
  removeNotification: (notification: Notification) => void | undefined;
}

export const NotificationContext = createContext<NotificationProviderProps>({
  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},
});

export const NotificationProvider = ({ children }: any) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = (notification: Notification) => {
    setNotifications([...notifications, notification]);
  };

  const removeNotification = (notification: Notification) => {
    setNotifications([
      ...notifications.filter((n) => n.id !== notification.id),
    ]);
  };

  return (
    <NotificationContext.Provider
      value={{ notifications, addNotification, removeNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
