import {
  UilDashboard,
  UilDatabase,
  UilFileExport,
  UilTruck,
  UilUsersAlt,
  // @ts-ignore
} from '@iconscout/react-unicons';
import AppWrapper from '../App';
import { PaginationProvider } from '../context/PaginationContext';
import { ImportNew } from './data/import/new/page';
import { ImportView } from './data/import/page';
import Data from './data/page';
import { ErrorPage } from './error';
import Home from './page';
import Suppliers from './suppliers/page';
import Users from './users/page';

export const mainRoutes = [
  {
    label: 'Dashboard',
    path: '/',
    element: <Home />,
    show: true,
    icon: (color = 'gray') => <UilDashboard color={color} size={20} />,
  },
  {
    label: 'Data',
    path: '/data',
    element: (
      <PaginationProvider>
        <Data />
      </PaginationProvider>
    ),
    icon: (color = 'gray') => <UilDatabase color={color} size={20} />,
    show: true,
  },

  {
    label: 'Data',
    path: '/data/import',
    element: (
      <PaginationProvider>
        <ImportView />
      </PaginationProvider>
    ),
  },
  {
    label: 'Data',
    path: '/data/import/:id',
    element: <ImportNew />,
  },
  {
    label: 'Users',
    path: '/users',
    element: (
      <PaginationProvider>
        <Users />
      </PaginationProvider>
    ),
    icon: (color = 'gray') => <UilUsersAlt color={color} size={20} />,
    show: true,
  },
  {
    label: 'Suppliers',
    path: '/suppliers',
    element: (
      <PaginationProvider>
        <Suppliers />
      </PaginationProvider>
    ),
    show: true,
    icon: (color = 'gray') => <UilTruck color={color} size={20} />,
  },
];

export const routes = [
  {
    label: 'Home',
    path: '/',
    element: <AppWrapper />,
    show: true,
    icon: (color = 'gray') => <UilDashboard color={color} size={20} />,
    children: [...mainRoutes],
    errorElement: <ErrorPage />,
  },
];
