export type WidgetDataSet = {
  label: string;
  data: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
};

export type WidgetData = {
  labels?: string[];
  datasets: Array<{
    label: string;
    data: any[];
    backgroundColor?: string | string[];
    borderColor?: string | string[];
    borderWidth?: number;
    fill?: boolean;
    stack?: string;
  }>;
};

export enum ChartType {
  PIE,
  BAR,
  PIE_TIME_SERIES,
  STACKED_BAR_TIME_SERIES,
  LINE_TIME_SERIES,
  GROUPED_BAR_HORIZONTAL,
}

export type DashboardWidget = {
  id: string;
  name: string;
  hasFilters: boolean;
  type: ChartType;
  description: string;
  data?: WidgetData | null | any;
  options?: Record<string, any>;
  filters?: {
    options?: string[];
    selectedOption: string;
    onSelect: (value: string) => void;
  };
};
