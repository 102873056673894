import Papa from 'papaparse';
import { ReactNode } from 'react';
import { RowFormat } from '../ImportForm';
import moment from 'moment';

const mapping = {
  Description: { header: 'description', required: true },
  Quantity: { header: 'quantity', required: true },
  'Unit Price': { header: 'unitPrice', required: true },
  'Date of Purchase': { header: 'dateOfPurchase', required: true },
  Supplier: { header: 'supplier', required: true },
  'Supplier Location': { header: 'supplierLocation', required: true },
  'Supplier Country': { header: 'supplierCountry', required: false },
  Recycled: { header: 'recycled', required: false },
  'Unit of Measurement': { header: 'unitOfMeasure', required: false },
  Sector: { header: 'sector', required: false },
  'Sub-sector': { header: 'subSector', required: false },
  Manufacturer: { header: 'manufacturer', required: false },
  'Country of Manufacture': { header: 'countryOfManufacture', required: false },
  'Supplier Distance to Client': {
    header: 'supplierDistance',
    required: false,
  },
  'Mode of Transport': { header: 'modeOfTransport', required: false },
  'Intensity Ratio': { header: 'intensityRatio', required: false },
  'Intensity Ratio Unit': { header: 'intensityRatioUnit', required: false },
  'Emission (Production)': { header: 'emissionProduct', required: false },
  'Emission (Transport)': { header: 'emissionTransport', required: false },
  'Emission (Total)': { header: 'emissionTotal', required: false },
  'Emissions Unit': { header: 'emissionsUnit', required: false },
};

function parseDate(rowDate: string) {
  const dateFormats = [
    'DD/MM/YYYY',
    'DD/MM/YY',
    'DD-MM-YYYY',
    'DD-MM-YY',
    'MM/YYYY',
    'MM-YYYY',
    'MM-YY',
    'MM/YY',
    'MMM YYYY',
    'MMM-YYYY',
    'MMM-YYY',
  ];
  let parsedDate = null;
  for (const format of dateFormats) {
    parsedDate = moment(rowDate, format, true);
    if (parsedDate.isValid()) {
      break;
    }
  }

  if (parsedDate && parsedDate.isValid()) {
    return parsedDate.startOf('month').toDate();
  } else {
    return false;
  }
}

export const readFileData = async (file: any) => {
  let headers: any[] = [];
  let data: any[] = [];
  let error = '';
  let meta;
  const results = Promise;

  await new Promise((resolve) => {
    Papa.parse(file, {
      header: true,
      // dynamicTyping: true,
      skipEmptyLines: true,
      complete: function (res, file) {
        headers = res.meta.fields as any[];
        data = res.data;
        meta = res.meta;
        resolve(true);
      },
      transformHeader(header: string, index: number): string {
        const trimmed = header.trim();
        // Lookup the header in the mapping
        // @ts-ignore
        const mapped = mapping[trimmed]?.header;
        if (mapped) {
          return mapped;
        }
        // If not found, return the original header
        return trimmed;
      },
      error: function (err, _) {
        console.error('Error parsing CSV', err);
        error = 'There was an error reading your input file.';
      },
    });
  });

  const errors: string[] = [];
  Object.keys(mapping).forEach((key) => {
    // check the required headers are present
    // @ts-ignore
    if (mapping[key].required && !headers.includes(mapping[key].header)) {
      errors.push(`"${key}" is a required column.`);
    }
  });

  // check if any headers are not expected
  headers.forEach((header) => {
    // @ts-ignore
    if (
      !Object.values(mapping)
        .map((m) => m.header)
        .includes(header)
    ) {
      errors.push(`"${header}" is not a valid column.`);
    }
  });

  if (errors.length > 0) {
    return {
      results: [],
      error: 'Could not validate your input file.',
      errors: errors,
    };
  }

  if (data.length == 0) {
    error = 'Your Input file does not contain any data.';
  }

  let invalidDates = false;
  let invalidQuantities = false;
  const invalidDateRows = data.filter((row) => {
    let valid = true;
    try {
      if (!parseDate(row.dateOfPurchase)) {
        invalidDates = true;
        valid = false;
      }
      const quantity = parseFloat(row.quantity);
      if (isNaN(quantity) || quantity <= 0 || !Number.isInteger(quantity)) {
        invalidQuantities = true;
        valid = false;
      }
    } catch (error) {
      return true;
    }
    return !valid;
  });

  if (invalidDates) {
    const supportedFormats = [
      'MM/DD/YYYY',
      'MM-DD-YYYY',
      'MM/YYYY',
      'MM-YYYY',
      'MM-YY',
      'MM/YY',
      'MMM YYYY',
      'MMM-YYYY',
    ];

    const error = `Some rows have invalid date formats in the 'Date of Purchase' column. Supported formats: ${supportedFormats.join(
      ',  '
    )}`;
    return {
      results: [],
      error,
      errors: [],
    };
  }

  if (invalidQuantities) {
    const error = `Some rows have missing or invalid quantities in the 'Quantity' column. Ensure each row has a value greater than 0`;
    return {
      results: [],
      error,
      errors: [],
    };
  }

  return {
    results: data as RowFormat[],
    error: error,
  };
};

export const printErrors = (errors: string[] | undefined) => {
  if (!errors) {
    return null;
  }
  let numberOfErrors = 0;
  const errorRows: ReactNode[] = [];

  try {
    errors.forEach((value, key) => {
      errorRows.push(
        <div key={key} className="mt-4">
          <ul role="list" className=" ml-4 list-disc space-y-1 pl-5">
            {value}
          </ul>
        </div>
      );

      numberOfErrors++;
      if (numberOfErrors > 20) {
        errorRows.push(
          <p key="error" className="mt-4">
            ... and {errors.length - 20} more
          </p>
        );
        throw new Error('too many errors');
      }
    });
  } catch (e) {
    // just catch the error and return what we have
  }

  return errorRows;
};

export const saveFileToS3 = async (
  file: any,
  presignedUrl: string
): Promise<boolean> => {
  // post the file to the url
  try {
    const myHeaders = new Headers({ 'Content-Type': 'text/csv' });
    await fetch(presignedUrl, {
      method: 'PUT',
      headers: myHeaders,
      // @ts-ignore
      body: file,
    });
    return true;
  } catch (error) {
    console.error('Error uploading logo:', error);
    return false;
  }
};

export const uploadDataToAPI = async (
  rows: RowFormat[],
  id: string,
  postToAPI: any
) => {
  // break the rows into chunks of 50
  const chunkSize = 1000;
  const chunks = [];
  for (let i = 0; i < rows.length; i += chunkSize) {
    chunks.push(rows.slice(i, i + chunkSize));
  }

  let errs: string[] = [];

  // loop over the chunks and send them to the API
  for (const chunk of chunks) {
    const result = await postToAPI({
      variables: {
        input: {
          importId: id,
          rows: chunk,
        },
      },
    });
    if (!result?.data?.saveDataToImport?.success) {
      errs.push(result.data.saveDataToImport.errors);
    }
  }

  return {
    error:
      errs.length > 0
        ? 'There was an error uploading your data, please try again. If this problem persists please contact support'
        : null,
    errors: errs,
  };
};
