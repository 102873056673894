import { useState } from 'react';

import { mainRoutes } from '../../app/routes';
import { QuickLinks } from './QuickLinks';

export function DefaultNav() {
  // State to handle the visibility of the mobile menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to toggle the mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="w-full px-10">
        {/* Hamburger Icon */}
        <div className="lg:hidden flex items-center justify-end">
          <button onClick={toggleMenu}>
            {/* Icon for the hamburger menu */}
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>
        <div
          style={{ backdropFilter: 'blur(5px)', height: '400px' }}
          className={`${
            isMenuOpen ? 'block' : 'hidden'
          } lg:hidden absolute w-full z-50 left-0 top-[88px] py-10 px-2 overflow-y-scroll lg:overflow-y-auto`}
        >
          <QuickLinks links={mainRoutes} toggleMenu={toggleMenu} />
        </div>
      </div>
    </>
  );
}
