import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Fragment, useContext, useRef, useState } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import { OrganisationContext } from '../../context/OrganisationContext';
export interface UserTableProps {
  users: {
    email: string;
  }[];
  count: number;
  setPage: (page: number) => void;
  pageNumber: number;
  pageSize: number;
  isDeleting?: boolean;
  deleteUser?: (props: any) => Promise<any>;
}

export const UserTable = ({
  users,
  count,
  setPage,
  pageNumber,
  pageSize,
  isDeleting,
  deleteUser,
}: UserTableProps) => {
  const { activeOrganisation } = useContext(OrganisationContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState<string | null>(null);
  const cancelButtonRef = useRef(null);
  return (
    <>
      <ul role="list" className="divide-y divide-gray-100">
        {users.map((person) => (
          <li key={person.email} className="flex justify-between gap-x-6 py-5">
            <div className="flex w-full justify-between items-center">
              <p className="flex text-sm leading-5 text-gray-500">
                <p className="truncate">{person.email}</p>
              </p>
              {deleteUser ? (
                <button
                  type="button"
                  onClick={() => {
                    setUserToDelete(person.email);
                    setShowDeleteModal(true);
                  }}
                  className={`col-span-4 capitalize transition-all justify-center py-2 px-4 border disabled:bg-gray-400 font-semibold border-transparent shadow-sm text-sm rounded-md text-white bg-gray-800 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900`}
                >
                  Delete
                </button>
              ) : null}
              <Transition.Root show={showDeleteModal} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-10"
                  initialFocus={cancelButtonRef}
                  onClose={setShowDeleteModal}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                          <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                              <ExclamationTriangleIcon
                                className="h-6 w-6 text-red-600"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                              <Dialog.Title
                                as="h3"
                                className="text-base font-semibold leading-6 text-gray-900"
                              >
                                Delete User
                              </Dialog.Title>
                              <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                  Are you sure you want to delete user{' '}
                                  {userToDelete}? They will lose access to this
                                  organisations' data.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button
                              type="button"
                              className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                              onClick={async () => {
                                if (deleteUser) {
                                  await deleteUser({
                                    variables: {
                                      input: {
                                        email: userToDelete,
                                        organisationId: activeOrganisation?.id,
                                      },
                                    },
                                  });
                                }
                              }}
                            >
                              {isDeleting ? (
                                <div className="border-dashed border-2 rounded-full animate-spin w-5 h-5" />
                              ) : (
                                'Delete'
                              )}
                            </button>
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                              onClick={() => setShowDeleteModal(false)}
                              ref={cancelButtonRef}
                            >
                              Cancel
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            </div>
          </li>
        ))}
      </ul>
      <Pagination
        goToPage={setPage}
        total={count}
        currentPage={pageNumber}
        pageSize={pageSize}
      />
    </>
  );
};
