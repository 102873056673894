import { gql } from '@apollo/client';

export const getUsers = gql`
  query GetUsers($activeOrganisation: String, $input: GenericFindOptions) {
    getUsers(activeOrganisation: $activeOrganisation, input: $input) {
      count
      results {
        id
        email
      }
      response {
        success
      }
    }
  }
`;
