import { classNames } from '../../utils/utils';
import { DotLoader } from 'react-spinners';

export interface ProgressProps {
  steps: {
    title: string;
    completed: boolean;
  }[];
  title?: string;
  colour?: string;
  spinner: {
    colour: string;
    show: boolean;
  };
}

export default function Progress({
  title,
  steps,
  colour = 'green-500',
  spinner,
}: ProgressProps) {
  const gridCols = `grid-cols-${steps.length}`;
  const progressColour = `bg-${colour}`;
  const currentPercentage =
    (steps.filter((step) => {
      return step.completed;
    }).length /
      steps.length) *
    100;

  return (
    <div>
      <div className="flex justify-between">
        {title && (
          <>
            <h4 className="sr-only">Status</h4>
            <p className="text-sm font-medium text-gray-900 my-auto">
              {title}...
            </p>
          </>
        )}
        {spinner.show && <DotLoader color={spinner.colour} />}
      </div>
      <div className="mt-6" aria-hidden="true">
        <div className="overflow-hidden rounded-full bg-gray-200">
          <div
            className={classNames('h-2 rounded-full', progressColour)}
            style={{ width: `${currentPercentage}%` }}
          />
        </div>
        <div
          className={classNames(
            `mt-6 hidden text-sm font-medium text-gray-600 sm:grid`,
            gridCols
          )}
        >
          {steps.map((step, i) => (
            <div
              key={i}
              className={classNames(
                step.completed ? `text-${colour} font-bold` : 'text-black',
                i === 0
                  ? ''
                  : i < steps.length - 1
                  ? 'text-center'
                  : 'text-right'
              )}
            >
              {step.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
