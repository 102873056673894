import { Auth, Hub } from 'aws-amplify';
import * as React from 'react';
import { User } from '../types/User';
import md5 from 'md5';

interface UserContextProps {
  user: User;
  isLoading: boolean;
}

const UNAUTHENTICATED_USER = {
  attributes: {},
  isAuthenticated: false,
  username: '',
  id: '',
  roles: [],
};

export const AuthenticatedUserContext = React.createContext<UserContextProps>({
  user: UNAUTHENTICATED_USER,
  isLoading: true,
});

export const AuthenticatedUserProvider: React.FunctionComponent<
  React.PropsWithChildren
> = (props) => {
  const [user, setUser] = React.useState<User>(UNAUTHENTICATED_USER);
  const [isLoading, setIsLoading] = React.useState(true);
  async function retrieveUserAndSetAsCurrent() {
    try {
      const userSession = await Auth.currentAuthenticatedUser();
      setUser({
        isAuthenticated: true,
        attributes: {
          email: userSession.attributes.email,
          picture: userSession.attributes.picture
            ? userSession.attributes.picture
            : `https://gravatar.com/avatar/${md5(
                userSession.attributes.email
              )}?d=mp`,
        },
        username: userSession.attributes.email || '',
        id: userSession.attributes.sub,
        roles: userSession?.signInUserSession?.accessToken?.payload[
          'cognito:groups'
        ]?.map((role: any) => ({ id: role })),
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    return Hub.listen('auth', ({ payload: { event, data: userSession } }) => {
      switch (event) {
        case 'signIn':
          setUser({
            isAuthenticated: true,
            attributes: {
              email: userSession.attributes.email,
              picture: userSession.attributes.picture,
            },
            username: userSession.attributes.email || '',
            id: userSession.attributes.sub,
            roles: userSession?.signInUserSession?.accessToken?.payload[
              'cognito:groups'
            ]?.map((role: any) => ({ id: role })),
          });
          break;
        case 'signOut':
          setUser(UNAUTHENTICATED_USER);
          break;
      }
    });
  }, [user]);

  React.useEffect(() => {
    retrieveUserAndSetAsCurrent();
  }, []);

  return (
    <AuthenticatedUserContext.Provider value={{ user, isLoading }}>
      {props.children}
    </AuthenticatedUserContext.Provider>
  );
};
