import { createContext, useContext, useState } from 'react';

interface PaginationContextProps {
  limit: number;
  offset: number;
  setLimit: (num: number) => void;
  setOffset: (num: number) => void;
}

const PaginationContext = createContext<PaginationContextProps>({
  limit: 50,
  offset: 0,
  setLimit: () => null,
  setOffset: () => null,
});

export function usePagination() {
  return useContext(PaginationContext);
}

interface PaginationProviderProps {
  children: React.ReactElement;
}

export const PaginationProvider = ({ children }: PaginationProviderProps) => {
  const [limit, setLimit] = useState<number>(50);
  const [offset, setOffset] = useState<number>(0);

  const value = {
    limit,
    offset,
    setLimit,
    setOffset,
  };

  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  );
};
