import { pdf } from '@react-pdf/renderer';
import { useContext, useEffect, useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { saveAs } from 'file-saver';

import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  UilFilter,
  // @ts-ignore
} from '@iconscout/react-unicons';
import { useNavigate } from 'react-router-dom';
import DashboardWidgets from '../components/DashboardGrid';
import OrganisationPicker from '../components/Header/OrganisationPicker';
import PDFDocument from '../components/PDFDocument/PDFDocument';
import StatBox from '../components/StatBox';
import { AuthenticatedUserContext } from '../context/AuthenticatedUserContext';
import { OrganisationContext } from '../context/OrganisationContext';
import { getEmissionsData } from '../graphql/queries/getEmissionsData';
import { useOrganisationAwareApollo } from '../hooks/useOrganisationAwareApollo';
import { ChartEnum } from '../types/Charts';
import { ChartType, DashboardWidget } from '../types/Dashboard';
import { Statistics } from '../types/Stats';
import { DateSelection } from '../types/DateSelection';
import moment from 'moment';
import { getChartData } from '../components/DashboardGrid/chartDataMapping';
import { IoInformationCircleOutline } from 'react-icons/io5';

enum EmissionSelection {
  PRODUCT = 'Product Emissions',
  TOTAL = 'Total Emissions',
  TRANSPORT = 'Transport',
}

const chartColors = [
  '#6296bc',
  '#3ab067',
  '#eebb38',
  '#8bdbc7',
  '#efa7d1',
  '#ed7680',
  '#4d7b81',
  '#e5873b',
  '#a998b6',
  '#e5873b',
  '#6ea4bf',
  '#40b27d',
  '#eedc77',
  '#97cec4',
  '#f4b2d8',
  '#f28998',
  '#5e8c89',
  '#ef9a4d',
  '#b9a2c2',
  '#ef9a4d',
];

const EmissionsUnits = {
  kgCO2e: 'kg CO2e',
  mtCO2e: 'mt CO2e',
};

function getEmissionsValue(
  emissionUnit: string,
  selection: EmissionSelection,
  item: any
) {
  let result;

  switch (selection) {
    case EmissionSelection.PRODUCT:
      result = item?.productEmissions;
      break;
    case EmissionSelection.TOTAL:
      result = item?.totalEmissions;
      break;
    case EmissionSelection.TRANSPORT:
      result = item?.transportEmissions;
      break;
  }

  if (emissionUnit === EmissionsUnits.mtCO2e) {
    result = result / 1000;
  }
  return `${result}`;
}

export default function Home() {
  const [chartFilterSelection, setChartFilterSelection] = useState({
    [ChartEnum.TOTAL_EXPENDITURE]: EmissionSelection.TOTAL,
    [ChartEnum.EMISSIONS_BY_MANUFACTURER]: EmissionSelection.TOTAL,
    [ChartEnum.EMISSIONS_BY_SUPPLIER]: EmissionSelection.TOTAL,
    [ChartEnum.EMISSIONS_BY_SECTOR]: EmissionSelection.TOTAL,
    [ChartEnum.EMISSIONS_BY_SUB_SECTOR]: EmissionSelection.TOTAL,
    [ChartEnum.EMISSIONS_FROM_TRANSPORT_BY_SUPPLIER]:
      EmissionSelection.TRANSPORT,
  });

  const navigate = useNavigate();

  const [dateSelection, setDateSelection] = useState('LAST_TWELVE_MONTHS');

  const [emissionsUnit, setEmissionsUnit] = useState(EmissionsUnits.kgCO2e);
  const [supplierSelection, setSupplierSelection] = useState<
    string | undefined | null
  >(undefined);

  const { activeOrganisation } = useContext(OrganisationContext);
  const { user } = useContext(AuthenticatedUserContext);

  const { useLazyQuery } = useOrganisationAwareApollo();

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const [fetch, { data, loading }] = useLazyQuery(getEmissionsData, {
    variables: {
      query: {
        query: [
          {
            calculation: 'EmissionsByManufacturer',
            dateSelection,
            supplierSelection,
          },
          {
            calculation: 'EmissionsBySector',
            dateSelection,
            supplierSelection,
          },
          {
            calculation: 'EmissionsBySubSector',
            dateSelection,
            supplierSelection,
          },
          {
            calculation: 'EmissionsTransportBySupplier',
            dateSelection,
            supplierSelection,
          },
          {
            calculation: 'TotalExpenditurePerKgCO2e',
            dateSelection,
            supplierSelection,
          },
          {
            calculation: 'EmissionsBySupplier',
            dateSelection,
            supplierSelection,
          },
          {
            calculation: 'TotalEmissions',
            dateSelection: 'ALL_TIME',
            supplierSelection: undefined,
          },
          {
            calculation: 'NoOfSuppliers',
            dateSelection: 'ALL_TIME',
            supplierSelection: undefined,
          },
          {
            calculation: 'TotalExpenditure',
            dateSelection: 'ALL_TIME',
            supplierSelection: undefined,
          },
        ],
      },
    },
    fetchPolicy: 'network-only',
  });

  // Avoids UI flicker when loading
  const [resolvedData, setResolvedData] = useState(data);

  const [chartRefs, setChartRefs] = useState<any[]>([]);

  useEffect(() => {
    if (user.isAuthenticated && activeOrganisation) {
      fetch();
    }
  }, [dateSelection, user.isAuthenticated, activeOrganisation]);

  // Avoids UI flicker when loading
  useEffect(() => {
    if (!loading && data) {
      setResolvedData(data);
    }
  }, [data, loading]);

  const {
    EmissionsByManufacturer,
    EmissionsBySector,
    EmissionsBySubSector,
    EmissionsBySupplier,
    EmissionsTransportBySupplier,
    TotalExpenditurePerKgCO2e,
    TotalEmissions,
    TotalExpenditure,
    NoOfSuppliers,
  } = resolvedData?.getEmissionsData || {
    EmissionsByManufacturer: null,
    EmissionsBySector: null,
    EmissionsBySubSector: null,
    EmissionsBySupplier: null,
    EmissionsTransportBySupplier: null,
    TotalExpenditurePerKgCO2e: null,
    TotalEmissions: null,
    TotalExpenditure: null,
    NoOfSuppliers: null,
  };

  const widgets = [
    {
      id: ChartEnum.TOTAL_EXPENDITURE,
      name: 'Total Expenditure / Emissions',
      hasFilters: false,
      // description:
      //   'This chart shows the total expenditure by suppliers over time in relation to the total amount of CO2e generated by suppliers. It will allow you to visualize the relationship between spending and emissions.',
      type: ChartType.LINE_TIME_SERIES,
      filters: {
        selectedOption: chartFilterSelection[ChartEnum.TOTAL_EXPENDITURE],
        options: [
          EmissionSelection.TOTAL,
          EmissionSelection.PRODUCT,
          EmissionSelection.TRANSPORT,
        ],
        onSelect: (value: string) => {
          setChartFilterSelection({
            ...chartFilterSelection,
            [ChartEnum.TOTAL_EXPENDITURE]: value as EmissionSelection,
          });
        },
      },
      data: TotalExpenditurePerKgCO2e
        ? {
            datasets: [
              {
                label: 'Total Expenditure (GBP)',
                yAxisID: 'A',
                data: TotalExpenditurePerKgCO2e.map((item: any) => ({
                  x: item.purchaseMonth,
                  y: parseFloat(item.totalExpenditure),
                })),
                borderColor: chartColors[2],
                backgroundColor: chartColors[2],

                fill: false,
              },
              {
                label: 'Emissions',
                yAxisID: 'B',
                data: TotalExpenditurePerKgCO2e.map((item: any) => ({
                  x: item.purchaseMonth,
                  y: parseFloat(
                    getEmissionsValue(
                      emissionsUnit,
                      chartFilterSelection[ChartEnum.TOTAL_EXPENDITURE],
                      item
                    )
                  ),
                })),
                borderColor: chartColors[1],
                backgroundColor: chartColors[1],
                fill: false,
              },
            ],
          }
        : null,
    },
    {
      id: ChartEnum.EMISSIONS_BY_MANUFACTURER,
      name: 'Emissions by Manufacturer',
      hasFilters: true,
      // description: 'This chart breaks down emissions by manufacturer.',
      type: ChartType.PIE,
      filters: {
        selectedOption:
          chartFilterSelection[ChartEnum.EMISSIONS_BY_MANUFACTURER],
        options: [
          EmissionSelection.TOTAL,
          EmissionSelection.PRODUCT,
          EmissionSelection.TRANSPORT,
        ],
        onSelect: (value: string) =>
          setChartFilterSelection({
            ...chartFilterSelection,
            [ChartEnum.EMISSIONS_BY_MANUFACTURER]: value as EmissionSelection,
          }),
      },
      data: EmissionsByManufacturer
        ? {
            labels: EmissionsByManufacturer?.map(
              (item: any) => item.manufacturer
            ),
            datasets: [
              {
                label: 'Emissions',
                data: EmissionsByManufacturer?.map((item: any) =>
                  parseFloat(
                    getEmissionsValue(
                      emissionsUnit,
                      chartFilterSelection[ChartEnum.EMISSIONS_BY_MANUFACTURER],
                      item
                    )
                  )
                ),
                backgroundColor: chartColors,
                borderColor: chartColors,
                borderWidth: 1,
              },
            ],
          }
        : null,
    },
    {
      id: ChartEnum.EMISSIONS_BY_SUPPLIER,
      name: 'Emissions by Supplier',
      hasFilters: true,
      // description:
      //   'This chart represents the total emissions associated with each supplier. It is essentially a breakdown of emissions per supplier.',
      type: ChartType.BAR,
      filters: {
        selectedOption: chartFilterSelection[ChartEnum.EMISSIONS_BY_SUPPLIER],
        options: [
          EmissionSelection.TOTAL,
          EmissionSelection.PRODUCT,
          EmissionSelection.TRANSPORT,
        ],
        onSelect: (value: string) =>
          setChartFilterSelection({
            ...chartFilterSelection,
            [ChartEnum.EMISSIONS_BY_SUPPLIER]: value as EmissionSelection,
          }),
      },
      data: EmissionsBySupplier
        ? {
            labels: EmissionsBySupplier.map((item: any) => item.name),
            datasets: [
              {
                label: 'Emissions',
                data: EmissionsBySupplier.map((item: any) =>
                  parseFloat(
                    getEmissionsValue(
                      emissionsUnit,
                      chartFilterSelection[ChartEnum.EMISSIONS_BY_SUPPLIER],
                      item
                    )
                  )
                ),
                backgroundColor: chartColors[0],
                borderColor: chartColors[0],
                borderWidth: 1,
              },
            ],
          }
        : null,
    },
    {
      id: ChartEnum.EMISSIONS_BY_SECTOR,
      name: 'Emissions by Sector',
      hasFilters: false,
      // description:
      //   'This chart represents emissions distributed by industry sectors.',
      type: ChartType.PIE,
      filters: {
        selectedOption: chartFilterSelection[ChartEnum.EMISSIONS_BY_SECTOR],
        options: [
          EmissionSelection.TOTAL,
          EmissionSelection.PRODUCT,
          EmissionSelection.TRANSPORT,
        ],
        onSelect: (value: string) =>
          setChartFilterSelection({
            ...chartFilterSelection,
            [ChartEnum.EMISSIONS_BY_SECTOR]: value as EmissionSelection,
          }),
      },
      data: EmissionsBySector
        ? {
            labels: EmissionsBySector?.map((item: any) => item.name),
            datasets: [
              {
                label: 'Emissions',
                data: EmissionsBySector?.map((item: any) =>
                  parseFloat(
                    getEmissionsValue(
                      emissionsUnit,
                      chartFilterSelection[ChartEnum.EMISSIONS_BY_SECTOR],
                      item
                    )
                  )
                ),
                backgroundColor: chartColors,
                borderColor: chartColors,
              },
            ],
          }
        : null,
    },
    {
      id: ChartEnum.EMISSIONS_BY_SUB_SECTOR,
      name: 'Emissions by Sub-sector',
      hasFilters: false,
      // description:
      //   'This chart breaks down the emissions by sub-sectors within major sectors.',
      type: ChartType.GROUPED_BAR_HORIZONTAL,
      filters: {
        selectedOption: chartFilterSelection[ChartEnum.EMISSIONS_BY_SUB_SECTOR],
        options: [
          EmissionSelection.TOTAL,
          EmissionSelection.PRODUCT,
          EmissionSelection.TRANSPORT,
        ],
        onSelect: (value: string) =>
          setChartFilterSelection({
            ...chartFilterSelection,
            [ChartEnum.EMISSIONS_BY_SUB_SECTOR]: value as EmissionSelection,
          }),
      },
      data: {
        labels: [
          // @ts-ignore
          ...new Set(EmissionsBySubSector?.map((item) => item.sectorName)),
        ],

        datasets: [
          // @ts-ignore
          ...new Set(EmissionsBySubSector?.map((item) => item.subSectorName)),
        ].map((subsector, index) => ({
          label: subsector,

          data: [
            // @ts-ignore
            ...new Set(EmissionsBySubSector?.map((item) => item.sectorName)),
          ].map((sectorName) => {
            const emissionRecord = EmissionsBySubSector?.find(
              (item: any) =>
                item.sectorName === sectorName &&
                item.subSectorName === subsector
            );
            return parseFloat(
              getEmissionsValue(
                emissionsUnit,
                chartFilterSelection[ChartEnum.EMISSIONS_BY_SUB_SECTOR],
                emissionRecord
              )
            );
          }),
          backgroundColor: chartColors[index % chartColors.length],
          borderColor: chartColors[index % chartColors.length],
          stack: `stack`,
        })),
      },
    },
    {
      id: ChartEnum.EMISSIONS_FROM_TRANSPORT_BY_SUPPLIER,
      name: 'Emissions from Transport by Supplier',
      hasFilters: false,
      // description:
      //   'This chart displays emissions incurred from the transport of goods by different suppliers over time. It breaks down transport emissions by supplier.',
      type: ChartType.GROUPED_BAR_HORIZONTAL,
      filters: {
        selectedOption:
          chartFilterSelection[ChartEnum.EMISSIONS_FROM_TRANSPORT_BY_SUPPLIER],
        options: [
          EmissionSelection.TOTAL,
          EmissionSelection.PRODUCT,
          EmissionSelection.TRANSPORT,
        ],
        onSelect: (value: string) =>
          setChartFilterSelection({
            ...chartFilterSelection,
            [ChartEnum.EMISSIONS_FROM_TRANSPORT_BY_SUPPLIER]:
              value as EmissionSelection,
          }),
      },
      data: {
        labels: [
          // @ts-ignore
          ...new Set(EmissionsTransportBySupplier?.map((item) => item.name)),
        ], // Inline unique suppliers

        datasets: [
          // @ts-ignore
          ...new Set(
            EmissionsTransportBySupplier?.map(
              (item: any) => item.modeOfTransport
            )
          ),
        ] // Inline unique modes of transport
          .map((mode, index) => ({
            label: mode,
            // @ts-ignore
            data: [
              // @ts-ignore
              ...new Set(
                EmissionsTransportBySupplier?.map((item: any) => item.name)
              ),
            ] // Inline unique suppliers
              .map((supplier) => {
                const emissionRecord = EmissionsTransportBySupplier?.find(
                  (item: any) =>
                    item.name === supplier && item.modeOfTransport === mode
                );
                return parseFloat(
                  getEmissionsValue(
                    emissionsUnit,
                    chartFilterSelection[
                      ChartEnum.EMISSIONS_FROM_TRANSPORT_BY_SUPPLIER
                    ],
                    emissionRecord
                  )
                ); // If no record is found, return 0 emissions
              }),
            backgroundColor: chartColors[index],
            borderColor: chartColors[index],
            stack: `stack`,
          })),
      },
    },
  ] as DashboardWidget[];

  const stats = [
    {
      title: 'CO2e Emissions',
      value:
        emissionsUnit === EmissionsUnits.kgCO2e
          ? TotalEmissions?.result
          : TotalEmissions?.result / 1000,
    },
    {
      title: 'No of Suppliers',
      value: NoOfSuppliers?.count,
    },
    {
      title: 'Total Expenditure',
      value: TotalExpenditure?.result,
      type: Statistics.MONETARY,
    },
  ];

  const [filterBarOpen, setFilterBarOpen] = useState(false);

  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  const generatePdfReport = async () => {
    setIsGeneratingPdf(true);
    const charts = [];
    // @ts-ignore
    chartRefs?.[0]?.render();
    await new Promise((r) => setTimeout(r, 2000));

    for (let i = 0; i < chartRefs.length; i++) {
      const chartRef = chartRefs?.[i];
      // @ts-ignore
      const imageUrl = chartRef?.ref?.current?.toBase64Image();
      // @ts-ignore
      charts.push({
        image: imageUrl,
        title: chartRef?.title,
        table: getChartData(chartRef?.id, resolvedData),
        imageStyle: [
          ChartEnum.EMISSIONS_BY_SECTOR,
          ChartEnum.EMISSIONS_BY_MANUFACTURER,
        ].includes(chartRef?.id)
          ? { width: '45%' }
          : { height: '50%' },
      });
    }
    chartRefs?.[0]?.complete();

    const blob = await pdf(
      <PDFDocument
        activeOrganisation={activeOrganisation}
        charts={charts}
        dateSelection={
          DateSelection[dateSelection as keyof typeof DateSelection]
        }
      />
    ).toBlob();

    saveAs(
      blob,
      `${activeOrganisation?.companyName}_EmissionsReport_${moment(
        new Date()
      ).format('MM_YYYY')}.pdf`
    );

    setIsGeneratingPdf(false);
  };

  const [datasetVisibility, setDatasetVisibility] = useState({});

  const handleLegendItemClick = (widgetId: any, legendItemName: string) => {
    setDatasetVisibility((prevState) => {
      // @ts-ignore
      const widgetVisibility = prevState[widgetId] || {};

      const isCurrentlyVisible = widgetVisibility[legendItemName] !== false;
      const updatedVisibility = {
        ...widgetVisibility,
        [legendItemName]: !isCurrentlyVisible,
      };

      return { ...prevState, [widgetId]: updatedVisibility };
    });
  };

  const filterKey = `${dateSelection}_${supplierSelection}_${emissionsUnit}`;

  return (
    <>
      <div className="md:hidden fixed z-50 py-4 bottom-0 border-t-2 pb-6 border-green-700 bg-primary w-full flex gap-y-2 flex-col md:flex-row md:items-end justify-between shadow-md px-8">
        <div
          onClick={() => setFilterBarOpen(!filterBarOpen)}
          className="flex items-center justify-between md:hidden bg-black hover:bg-green-800 text-white my-2 border-2 border-green-700 font-medium px-4 py-2 rounded-sm"
          role="button"
        >
          Filters
          <UilFilter color={'white'} size={30} />
        </div>
        {NoOfSuppliers?.results ? (
          <div
            className={`${
              filterBarOpen ? '' : 'hidden'
            } md:flex flex-col gap-y-2 hidden`}
          >
            <p className="text-sm text-white">Supplier Selection:</p>
            <select
              value={supplierSelection ?? ''}
              className="text-sm"
              onChange={(event) => {
                if (event.target.value === 'All') {
                  setSupplierSelection(null);
                } else {
                  setSupplierSelection(event.target.value as unknown as string);
                }
              }}
            >
              <option value={undefined}>All</option>
              {NoOfSuppliers?.results.map((option: any) => (
                <option key={option?.name} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        ) : null}
        <div
          className={`${
            filterBarOpen ? '' : 'hidden'
          } md:flex flex-col gap-y-2`}
        >
          <p className="text-sm text-white">Date Selection:</p>
          <select
            value={dateSelection}
            className="text-sm"
            onChange={(event) =>
              setDateSelection(event.target.value as DateSelection)
            }
          >
            {Object.entries(DateSelection).map((option) => (
              <option key={option[0]} value={option[0]}>
                {option[1]}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="border my-2 bg-gray-100 rounded-xl mx-2 shadow-md">
        <div className="w-full bg-white rounded-xl">
          <div className="flex flex-col md:flex-row py-2 w-full justify-between items-center border-b">
            <div className=" flex flex-col justify-between gap-y-2 items-start gap-x-10 rounded-t-md px-2 md:px-10 py-4">
              <p className="text-xl font-extrabold">Hi there 👋,</p>
              <div className="flex flex-col lg:flex-row gap-y-2 gap-x-2">
                <div
                  role="button"
                  onClick={() => navigate('/data/import')}
                  className="px-4 flex justify-center items-center rounded-lg gap-x-2 py-2 hover:bg-primary bg-mustard"
                >
                  <p className={`text-lg font-extrabold lg:text-sm text-white`}>
                    Upload Data
                  </p>
                </div>

                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white hover:bg-primary">
                      Export
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              disabled={isGeneratingPdf}
                              onClick={async () => await generatePdfReport()}
                              className="px-4 w-full flex justify-center items-center rounded-lg text-black hover:bg-gray-50 gap-x-2 py-2"
                            >
                              PDF
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                {isGeneratingPdf && (
                  <button className="px-4 flex justify-center items-center bg-accent rounded-lg hover:bg-primary gap-x-2 py-2">
                    <p
                      className={`text-lg font-extrabold lg:text-sm text-white`}
                    >
                      <div className="border-dashed border-2 rounded-full animate-spin w-5 h-5" />
                    </p>
                  </button>
                )}
              </div>
              {isGeneratingPdf ? (
                <p className="text-xs text-gray-600">
                  Note: This PDF will be generated with your currently applied
                  filters
                </p>
              ) : null}
            </div>
            <div className="px-4 py-1 border-dashed">
              <div className="flex justify-between items-center">
                <p
                  onMouseEnter={() => setIsTooltipVisible(true)}
                  onMouseLeave={() => setIsTooltipVisible(false)}
                  onClick={() => setIsTooltipVisible(!isTooltipVisible)}
                  className="text-sm mb-1 flex gap-x-2"
                >
                  Performance to Date:
                  <div className="relative flex items-center">
                    <IoInformationCircleOutline className="text-lg cursor-pointer" />
                    {isTooltipVisible && (
                      <div className="absolute z-10 w-64 p-2 bg-accent text-white text-sm rounded-md shadow-md -left-16 -mt-10">
                        Values are shown in Kg, unless otherwise selected
                      </div>
                    )}
                  </div>
                </p>
              </div>
              <StatBox stats={stats} />
            </div>
          </div>

          <div className=" flex flex-col justify-between gap-y-4 md:flex-row items-center gap-x-10 rounded-t-md md:px-10 py-4">
            <div className="flex flex-row items-center w-full gap-x-4">
              <div className="flex flex-row items-center w-full gap-x-4">
                <OrganisationPicker />
                <div className="flex flex-col">
                  <div className="font-bold text-base">
                    {activeOrganisation?.companyName}
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <p className="text-sm">Emissions Unit:</p>
                    <div className="flex items-center">
                      {Object.entries(EmissionsUnits).map(([value, label]) => (
                        <label
                          key={value}
                          className="inline-flex items-center mr-6"
                        >
                          <input
                            type="radio"
                            name="emissionsUnit"
                            value={value}
                            checked={emissionsUnit === label}
                            onChange={() => setEmissionsUnit(label)}
                            className="text-sm"
                          />
                          <span className="ml-2">{label}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {NoOfSuppliers?.results ? (
                <div className={`hidden flex-col gap-y-2`}>
                  <p className="text-sm">Supplier Selection:</p>
                  <select
                    value={supplierSelection ?? ''}
                    className="text-sm w-[200px]"
                    onChange={(event) => {
                      if (event.target.value === 'All') {
                        setSupplierSelection(null);
                      } else {
                        setSupplierSelection(
                          event.target.value as unknown as string
                        );
                      }
                    }}
                  >
                    <option value={undefined}>All</option>
                    {NoOfSuppliers?.results.map((option: any) => (
                      <option key={option?.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              <div className={`hidden md:flex flex-col gap-y-2`}>
                <p className="text-sm">Date Selection:</p>
                <select
                  value={dateSelection}
                  className="text-sm w-[200px]"
                  onChange={(event) =>
                    setDateSelection(event.target.value as DateSelection)
                  }
                >
                  {Object.entries(DateSelection).map((option) => (
                    <option key={option[0]} value={option[0]}>
                      {option[1]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <hr className="border-dashed" />

        <div className="mx-2 xl:mx-3 mt-5 pb-72 xl:pb-32">
          <DashboardWidgets
            filterKey={filterKey}
            chartRefs={chartRefs}
            setChartRefs={setChartRefs}
            rawData={resolvedData}
            dashboardWidgets={widgets}
            handleLegendItemClick={handleLegendItemClick}
            datasetVisibility={datasetVisibility}
          />
        </div>
      </div>
    </>
  );
}
