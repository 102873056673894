import { useRouteError } from 'react-router-dom';
import React from 'react';
import { NotFoundPage } from '../components/Errors/NotFoundPage';

export const ErrorPage = () => {
  const error: any = useRouteError();

  if (error.status === 404) {
    return <NotFoundPage />;
  }

  return (
    <div>
      <h1>Something went wrong</h1>
      <p>{error?.message}</p>
    </div>
  );
};
